import './App.css';
import React, {useState} from "react";
import { Howl, Howler } from 'howler';
import {Link} from 'react-router-dom';
import axios from "axios";

function UserInfo (props) {
  const initialUser = {
    name: "",
    email: "",
    phone_no: "",
    message: "",
    page: props.page || ""
  }
  const [userInfo, setUserInfo] = useState(initialUser);
  const [userInfoErrorMessage, setUserInfoErrorMessage] = useState("");

  const KeySound = new Howl({
    src: ['sounds/keystroke.mp3'],
    volume: 0.3
  });

  const onHover = () => {
    KeySound.play();
  }

  const handleUserUpdate = (field, value) => {
    let user = {...userInfo};
    user[field] = value;
    setUserInfo(user);
    if (userInfoErrorMessage) {
      setUserInfoErrorMessage("");
    }
  } 

  const isValidEmail = (email) => {
    // Define a regular expression pattern for email validation.
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }

  const handleUserSave = () => {
    if (!userInfo.name || !userInfo.email || !userInfo.phone_no) {
      setUserInfoErrorMessage("Please fill all the required fields.");
    } else if (!isValidEmail(userInfo.email)) {
      setUserInfoErrorMessage("Please enter a valid email.");
    } else if (userInfo.phone_no.length < 10) {
      setUserInfoErrorMessage("Please enter a valid contact number.");
    } else {
      axios.post("https://investmyfunds.in/imf-api/v1/contact-us/", userInfo)
      .then(() => {
        localStorage.setItem("user", JSON.stringify(userInfo));
        setUserInfoErrorMessage("Info Saved.");
        props.onSuccess();
      })
      .catch((err) => console.log("Error", err))
    }
  }

  return (
    <div className="d-flex justify-content-center">
      <div className='card'>
        <div className='card-body'>
          <h1 className='card-title text-center'>Just one more step!</h1>
          <h5 className='card-subtitle text-muted mt-3 text-center'>
            Please provide your contact details so that our team can reach out to you.
          </h5>
          {
            userInfoErrorMessage ? <span className='errorMessage'>{userInfoErrorMessage}</span> : <></>
          }
          <div className='mt-4 text-start'>
            <div className='form-group'>
              <label>Name<sup>*</sup></label>
              <input className='form-control' type='text' name='name' value={userInfo.name} onChange={(e) => handleUserUpdate("name", e.target.value)}></input>
            </div>
            <div className='form-group'>
              <label>Email<sup>*</sup></label>
              <input className='form-control' type='email' name='email' value={userInfo.email} onChange={(e) => handleUserUpdate("email", e.target.value)}></input>
            </div>
            <div className='form-group'>
              <label>Contact<sup>*</sup></label>
              <input className='form-control' type='text' name='phone_no' value={userInfo.phone_no} onChange={(e) => handleUserUpdate("phone_no", e.target.value)}></input>
            </div>
            <div className='form-group'>
              <label>Message/Query</label>
              <textarea className='form-control' rows={4} name='message' value={userInfo.message} onChange={(e) => handleUserUpdate("message", e.target.value)} placeholder='A short note for us to help you better.'></textarea>
            </div>
            <button className='form-control btn btnSubmit' onClick={handleUserSave}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserInfo;
